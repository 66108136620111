import request from '@/utils/ucenter_request.js'
// 头部 user/logon/signIn
export function signIn(params) {
  return request({
    url: '/user/logon/signIn',
    method: 'get',
    params
  })
}
// APP登录二维码 /user/logon/signInByQrcode
export function signInByQrcode(params) {
  return request({
    url: '/user/logon/signInByQrcode',
    method: 'get',
    params
  })
}
// 发送验证码 /user/logon/sendMobileCode
export function sendMobileCode(params) {
  return request({
    url: '/user/logon/sendMobileCode',
    method: 'get',
    params
  })
}
// 监控二维码登录 /user/logon/loginForApp
export function loginForApp(params) {
  return request({
    url: '/user/logon/loginForApp',
    method: 'get',
    params
  })
}
// 退出登录 /user/logon/signOut
export function signOut(params) {
  return request({
    url: '/user/logon/signOut',
    method: 'get',
    params
  })
}

// 金来客推广 注册 获取 验证码
export function sendRegistCode(params) {
  return request({
    url: '/ucenter/user/sendCode',
    method: 'get',
    params
  })
}

// 金来客推广 注册
export function checkRegistCode(params) {
  return request({
    url: '/ucenter/user/checkcode',
    method: 'get',
    params
  })
}
