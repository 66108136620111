<template>
  <div class="main">
    <!-- 头部 -->
    <div ref="header" class="header">
      <div class="headerBox">
        <div class="headerLeft">
          <img class="logo" src="@/assets/image/logo_new.png" alt="">
          <div class="menu">
            <div class="item">
              <div class="name">首页</div>
            </div>
            <div class="item">
              <div @click="goAboutUs">关于我们</div>
            </div>

          </div>
        </div>
        <div class="mobile">
          <span>
            免费使用开通电话：18910931905
          </span>
          <a href="https://open.work.weixin.qq.com" target="_blank"><img src="https://open.work.weixin.qq.com/service/img?id=wwb6bb99fb4251fe03&t=isp&c=white&s=medium" srcset="https://open.work.weixin.qq.com/service/img?id=wwb6bb99fb4251fe03&t=isp&c=white&s=medium@2x 2x" referrerpolicy="unsafe-url" alt="企业微信"></a>
        </div>
      </div>
    </div>
    <!-- banner -->
    <div class="div_top">
      <!-- <img src="@/assets/image/banner.png" alt=""> -->
      <div class="reg_top">
        <div class="reg_box">
          <p class="top_title">免费注册使用</p>
          <div class="input_item">
            <p class="input_title">手机号</p>
            <el-input v-model="telephone" class="inpw" placeholder="请输入手机号（必填）"></el-input>
          </div>
          <div class="code_box">
            <div class="input_item">
              <p class="input_title">验证码</p>
              <el-input v-model="mobile_code" class="inpw1" placeholder="请输入验证码"></el-input>
            </div>
            <span class="code_btn" @click="getCodeAction">{{ codeBtnText }}</span>
          </div>
          <div class="reg_bot">
            <span class="do_span" @click="registNow">立即注册</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 主体 -->
    <div class="main_content">
      <!-- tab -->
      <!-- <div class="tab">
        <div v-for="(item,index) in tabList" :key="index" class="tabItem" @mouseenter="lightCheck(index)" @mouseleave="clearLight()" @click="tabCheck(index)">
          <img v-if="lightIndex===''" :src="index==tabIndex?item.checkedImg:item.defaultImg" alt="">
          <img v-if="lightIndex!==''" :src="(index==tabIndex||index==lightIndex)?item.checkedImg:item.defaultImg" alt="">
        </div>
      </div> -->
      <!-- 新tab栏 -->
      <div class="new_tab">
        <div v-for="(item,index) in tabList" :key="index" class="tab_item">
          <div :class="index==tabIndex ? 'tab_top active_color1' : 'tab_top'">
            <span class="tab_top_txt">{{ slideList[index].main_title }}</span>
            <img v-if="index==0 || index==1" class="hot_icon" src="@/assets/image/hot_icon1.png" alt="">
          </div>
          <div :class="index==tabIndex ? 'tab_bot active_color2' : 'tab_bot'">
            <div class="tab_bot_items">
              <span v-for="(item1,index1) in slideList[index].silde" :key="index1" class="item_txt">{{ item1.title }}</span>
            </div>
            <p class="tab_detail" @click="tabCheck(index)">查看详情</p>
          </div>
        </div>
      </div>

      <!-- 功能列表 -->
      <!-- 侧边栏 -->
      <!-- <div class="slideBar">
        <div v-for="(item,index) in slideList[tabIndex].silde" :key="index" class="slideItem" @click="toFloor(index)">
          <span class="imgBox">
            <img class="circle" :src="slideList[tabIndex].silde[index].checked?require('@/assets/image/active.png'):require('@/assets/image/circle.png')" alt="">
          </span>
          <span :class="slideList[tabIndex].silde[index].checked?'active':''">{{ item.title }}</span>
          <div v-if="index!=slideList[tabIndex].silde.length-1" class="line">
            <img src="@/assets/image/line.png" alt="">
          </div>
        </div>
      </div> -->
      <!-- 企微右侧 -->
      <div v-if="tabIndex==0">
        <div class="classify_title">
          <div class="main_title">微信右侧-个人用户</div>
          <div class="sub_title">高效的微信右侧功能为营销人员赋予强大销售支持</div>
        </div>
        <div class="functionList">
          <!-- 客户画像 -->
          <div class="anhorTop anhorTop0">
            <img src="@/assets/image/WeChatRight/1.png" alt="">
          </div>
          <!-- 跟进sop -->
          <div class="anhorTop anhorTop1">
            <img src="@/assets/image/WeChatRight/2.png" alt="">
          </div>
          <!-- 跟进日历 -->
          <div class="anhorTop anhorTop2">
            <img src="@/assets/image/WeChatRight/3.png" alt="">
          </div>
          <!-- 客户线索 -->
          <div class="anhorTop anhorTop3">
            <img src="@/assets/image/WeChatRight/4.png" alt="">
          </div>
          <!-- 成交播报 -->
          <div class="anhorTop anhorTop4">
            <img src="@/assets/image/WeChatRight/5.png" alt="">
          </div>
          <!-- <div class="classify_title">
            <div class="main_title">微信右侧-个人用户</div>
            <div class="functionTitle">高效的微信右侧功能为营销人员赋予强大销售支持</div>
          </div>
          <div>
            <img src="@/assets/image/WeChatRight/6.png" alt="">
          </div> -->
          <!-- 社群运营 -->
          <div class="classify_title anhorTop anhorTop5">
            <div class="main_title">社群运营</div>
            <div class="functionTitle">高效的微信右侧功能为营销人员赋予强大销售支持</div>
          </div>
          <div>
            <img src="@/assets/image/WeChatRight/6.png" alt="">
          </div>
          <!-- 企业简介 -->
          <!-- <div class="classify_title anhorTop anhorTop6">
            <div class="main_title">企业简介</div>
            <div class="functionTitle">金来客是一家专注于社群运营和SCRM工具的SaaS平台的公司。</div>
            <div class="functionTitle">我们的使命是帮助企业有效管理和运营其社群，并提供高效的SCRM解决方案，以推动客户关系的增长和业务的成功。</div>
          </div> -->
          <!-- <div>
            <img src="@/assets/image/WeChatRight/7.png" alt="">
          </div> -->

          <!-- <div class="classify_title bottom_title">
            <div class="sub_title black">金来客，您值得信赖的社群运营和SCRM工具合作伙伴！</div>
            <div class="sub_title black">让我们携手共创成功，推动您的业务发展和客户关系的提升！</div>
          </div> -->
        </div>

      </div>
      <!-- 服务系统 -->
      <div v-if="tabIndex==3">
        <div class="content_classify_title">
          <div class="main_content_title">客户服务</div>
          <div class="sub_content_title">完善的售后支持系统，让您后顾无忧</div>
        </div>
        <div class="serveBox">
          <div class="mailItem">
            <div v-for="(item,index) in service" :key="index" class="anhorTop mailBox" :class="'anhorTop'+index">
              <!-- 第一种样式 -->
              <template v-if="index%3==0">
                <div class="mailTop">
                  <div class="innerTop">
                    <div class="mail_left">
                      <div class="icon"><img :src="item.main_icon" alt=""></div>
                      <div class="main_title">{{ item.main_title }}</div>
                      <div class="main_sub_title">{{ item.sub_title }}</div>

                    </div>
                    <div class="mail_right">
                      <img :src="item.rightImg" alt="">
                    </div>
                  </div>

                </div>
                <div class="mailBottom">
                  <div v-for="(value,key) in item.list" :key="key" class="mailBotItem" :class="item.list[key].checked?'on':''" @click="checkModuleServe(item,index,key)">
                    <div class="check">
                      <div class="img">
                        <img :src="value.icon" alt="">
                      </div>
                      <div class="title">
                        {{ value.text }}
                      </div>
                      <div class="desc">
                        {{ value.desc }}
                      </div>
                    </div>
                    <div class="bg_icon">
                      <img :src="value.bg_icon" alt="">
                    </div>
                  </div>
                </div>
              </template>
              <!-- 第二种样式 -->
              <template v-if="index%3==1">
                <div class="serveItem">
                  <div class="marking_left">
                    <div class="title">{{ item.main_title }}</div>
                    <div class="subTitle">{{ item.sub_title }}</div>
                    <div v-for="(value,key) in item.list" :key="key" class="info" :class="item.list[key].checked?'active':''" @click="checkModuleServe(item,index,key)">
                      <div class="info_left">
                        <img :src="value.icon" alt="">
                      </div>
                      <div class="info_right">
                        <div class="info_title">{{ value.text }}</div>
                        <div class="info_desc">{{ value.desc }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="marking_right">
                    <img :src="item.rightImg" alt="">
                  </div>
                </div>
              </template>
              <!-- 第三种样式 -->
              <template v-if="index%3==2">
                <div class="mailTop">
                  <div class="innerTop">
                    <div class="mail_right">
                      <img :src="item.rightImg" alt="">
                    </div>
                    <div class="mail_left">
                      <div class="icon"><img :src="item.main_icon" alt=""></div>
                      <div class="main_title">{{ item.main_title }}</div>
                      <div class="main_sub_title">{{ item.sub_title }}</div>

                    </div>
                  </div>

                </div>
                <div class="mailBottom">
                  <div v-for="(value,key) in item.list" :key="key" class="mailBotItemMore" :class="item.list[key].checked?'on':''" @click="checkModuleServe(item,index,key)">
                    <div class="check">
                      <div class="img">
                        <img :src="value.icon" alt="">
                      </div>
                      <div class="title">
                        {{ value.text }}
                      </div>
                      <div class="desc">
                        {{ value.desc }}
                      </div>
                    </div>
                    <div class="bg_icon">
                      <img :src="value.bg_icon" alt="">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 营销系统 -->
    <div v-if="tabIndex==1">
      <div class="content_classify_title">
        <div class="main_content_title">营销管理</div>
      </div>
      <div class="markingList">
        <div v-for="(item,index) in marketing" :key="index" class="markingBox">
          <div class="markingItem anhorTop" :class="'anhorTop'+index">
            <template v-if="index%2==0">
              <div class="marking_left">
                <div class="title">{{ item.main_title }}</div>
                <div class="subTitle">{{ item.sub_title }}</div>
                <div v-for="(value,key) in item.list" :key="key" class="info" :class="item.list[key].checked?'active':''" @click="checkModule(item,index,key)">
                  <div class="info_left">
                    <img :src="value.icon" alt="">
                  </div>
                  <div class="info_right">
                    <div class="info_title">{{ value.text }}</div>
                    <div class="info_desc">{{ value.desc }}</div>
                  </div>
                </div>
              </div>
              <div class="marking_right">
                <img :src="item.rightImg" alt="">
              </div>
            </template>
            <template v-if="index%2==1">
              <div class="marking_right">
                <img :src="item.rightImg" alt="">
              </div>
              <div class="marking_left">
                <div class="title">{{ item.main_title }}</div>
                <div class="subTitle">{{ item.sub_title }}</div>
                <div v-for="(value,key) in item.list" :key="key" class="info" :class="item.list[key].checked?'active':''" @click="checkModule(item,index,key)">
                  <div class="info_left">
                    <img :src="value.icon" alt="">
                  </div>
                  <div class="info_right">
                    <div class="info_title">{{ value.text }}</div>
                    <div class="info_desc">{{ value.desc }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

      </div>
    </div>
    <!-- 网站管理 -->
    <div v-if="tabIndex==2">
      <div class="content_classify_title">
        <div class="main_content_title">网站管理</div>
      </div>
      <div class="websiteList">
        <div v-for="(item,index) in website" :key="index" class="websiteBox">
          <div class="markingItem anhorTop" :class="'anhorTop'+index">
            <template v-if="index%2==0">
              <div class="marking_left">
                <div class="title">{{ item.main_title }}</div>
                <div class="subTitle">{{ item.sub_title }}</div>
                <div v-for="(value,key) in item.list" :key="key" class="info" :class="item.list[key].checked?'active':''" @click="checkModuleSite(item,index,key)">
                  <div class="info_left">
                    <img :src="value.icon" alt="">
                  </div>
                  <div class="info_right">
                    <div class="info_title">{{ value.text }}</div>
                    <div class="info_desc">{{ value.desc }}</div>
                  </div>
                </div>
              </div>
              <div class="marking_right">
                <img :src="item.rightImg" alt="">
              </div>
            </template>
            <template v-if="index%2==1">
              <div class="marking_right">
                <img :src="item.rightImg" alt="">
              </div>
              <div class="marking_left">
                <div class="title">{{ item.main_title }}</div>
                <div class="subTitle">{{ item.sub_title }}</div>
                <div v-for="(value,key) in item.list" :key="key" class="info" :class="item.list[key].checked?'active':''" @click="checkModuleSite(item,index,key)">
                  <div class="info_left">
                    <img :src="value.icon" alt="">
                  </div>
                  <div class="info_right">
                    <div class="info_title">{{ value.text }}</div>
                    <div class="info_desc">{{ value.desc }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

      </div>
    </div>
    <!-- 右侧二维码 -->
    <div class="rightfloat">
      <div class="regBox">
        <img class="regIcon" src="@/assets/image/reg_icon_img.png" alt="" @click="clickRegIcon">
      </div>
      <div class="hotline">
        <div class="hotlineImg">
          <img src="@/assets/leaflets/qrCode.png" alt="">
        </div>
      </div>
      <a href="https://help.kinglake.cloud" target="_blank">
        <div class="helpText">
          <img src="@/assets/image/helpText.png" alt="">帮助文档
        </div>
      </a>

    </div>
    <!-- 底部 -->
    <div class="footer">
      <p class="infos">
        <img src="https://newoss.zhulong.com/edu/202012/08/32/160232ahm9yclsgkogy1tc.png" alt="">
        京ICP备2022034276号-2 北京金来客科技有限公司 通讯地址：北京市石景山区实兴大街30号院3号楼2层D-2012
      </p>
    </div>
    <!-- 注册成功弹窗 -->
    <el-dialog
      title=""
      :visible.sync="customDialog"
      :close-on-click-modal="false"
      width="600px"
      lock-scroll
      top="15%"
      class="reg_dialog"
    >
      <div class="reg_body">
        <img class="icon11" src="@/assets/image/reg_result_duigou.png" alt="">
        <p class="text">注册成功，稍后工作人员会有工作人员联系您</p>
      </div>
    </el-dialog>
    <!-- <div v-if="customDialog" class="customRegDialog">
      <div class="dialog_body">
        <img class="icon11" src="@/assets/image/reg_result_duigou.png" alt="">
        <p class="text">注册成功，稍后工作人员会有工作人员联系您</p>
      </div>
    </div> -->
  </div>
</template>
<script>
import { sendRegistCode, checkRegistCode } from '../../api/login'
export default {
  data() {
    return {
      // banner下tab切换
      tabList: [{
        checkedImg: require('@/assets/image/nav/1.png'),
        defaultImg: require('@/assets/image/nav/2.png')
      }, {
        checkedImg: require('@/assets/image/nav/4.png'),
        defaultImg: require('@/assets/image/nav/3.png')
      }, {
        checkedImg: require('@/assets/image/nav/6.png'),
        defaultImg: require('@/assets/image/nav/5.png')
      }, {
        checkedImg: require('@/assets/image/nav/8.png'),
        defaultImg: require('@/assets/image/nav/7.png')
      }],
      tabIndex: 0,
      lightIndex: '',
      slideList: [{
        main_title: '企微右侧',
        silde: [{ title: '客户画像', checked: true }, { title: '跟进sop', checked: false }, { title: '跟进日历', checked: false }, { title: '客户线索', checked: false }, { title: '成交播报', checked: false }, { title: '社群运营', checked: false }, { title: '企业简介', checked: false }]
      }, {
        main_title: '营销管理',
        silde: [{ title: '推广管理', checked: true }, { title: '拉新获客', checked: false }, { title: '线索管理', checked: false }, { title: '客户管理', checked: false }, { title: '促销管理', checked: false }, { title: '团队管理', checked: false }, { title: 'SOP管理', checked: false }, { title: '营销看板', checked: false }]
      }, {
        main_title: '网站管理',
        silde: [{ title: '产品管理', checked: true }, { title: '自动建站', checked: false }, { title: '弹窗系统', checked: false }, { title: '会员管理', checked: false }]
      }, {
        main_title: '服务系统',
        silde: [{ title: '邮寄管理', checked: true }, { title: '积分商城', checked: false }, { title: '用户管理', checked: false }]
      }],
      anhorList: [],
      // 营销系统数据模拟
      marketing: [{
        main_title: '推广管理',
        sub_title: '帮您找到最佳的推广渠道和推广文案',
        rightImg: require('@/assets/image/marketingSystem/img/img-1/1.png'),
        list: [{
          icon: require('@/assets/image/marketingSystem/icon/3.png'),
          text: '素材管理',
          desc: '定义您的推广素材，包括推广文章、广告图片、短消息内容等',
          img: require('@/assets/image/marketingSystem/img/img-1/1.png'),
          checked: true
        }, {
          icon: require('@/assets/image/marketingSystem/icon/5.png'),
          text: '媒体资源',
          desc: '可以录入推广渠道信息及收费标准等内容，比如某公众号粉丝数量及发布一篇的价格',
          img: require('@/assets/image/marketingSystem/img/img-1/3.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/1.png'),
          text: '效率分析',
          desc: '广告投放后，可以自动统计该次投放的浏览量 R添加的好友数量R成交的数量以及获客成本等信息',
          img: require('@/assets/image/marketingSystem/img/img-1/2.png'),
          checked: false
        }]
      }, {
        main_title: '拉新获客',
        sub_title: '提供多种渠道、多重方法获客，增加私域好友的利器',
        rightImg: require('@/assets/image/marketingSystem/img/img-2/4.png'),
        list: [{
          icon: require('@/assets/image/marketingSystem/icon/4.png'),
          text: '渠道活码',
          desc: '利用渠道活码，可以统计每次推广添加的员工的好友数量',
          img: require('@/assets/image/marketingSystem/img/img-2/4.png'),
          checked: true
        }, {
          icon: require('@/assets/image/marketingSystem/icon/2.png'),
          text: '任务裂变',
          desc: '利用奖品鼓励用户好友助力裂变，增加私域好友数量',
          img: require('@/assets/image/marketingSystem/img/img-2/5.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/6.png'),
          text: '群裂变',
          desc: '利用奖品鼓励用户将好友拉进群，实现社群裂变',
          img: require('@/assets/image/marketingSystem/img/img-2/6.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/7.png'),
          text: '城市群裂变',
          desc: '鼓励同一城市同一爱好的用户组成群，有利于群的活跃',
          img: require('@/assets/image/marketingSystem/img/img-2/7.png'),
          checked: false
        }]
      }, {
        main_title: '线索管理',
        sub_title: '将注册用户或者优质线索有效分配给营销人员添加微信好友促成交易',
        rightImg: require('@/assets/image/marketingSystem/img/img-3/8.png'),
        list: [{
          icon: require('@/assets/image/marketingSystem/icon/9.png'),
          text: '公海线索',
          desc: '管理注册用户或者导入的手机号码形成的手机公海，方便营销人员添加好友',
          img: require('@/assets/image/marketingSystem/img/img-3/8.png'),
          checked: true
        }, {
          icon: require('@/assets/image/marketingSystem/icon/8.png'),
          text: '优质线索',
          desc: '利用奖品鼓励用户好友助力裂变，增加私域好友数量',
          img: require('@/assets/image/marketingSystem/img/img-3/9.png'),
          checked: false
        }]
      }, {
        main_title: '客户管理',
        sub_title: '可以轻松查询并管理公司私域里的好友',
        rightImg: require('@/assets/image/marketingSystem/img/img-4/10.png'),
        list: [{
          icon: require('@/assets/image/marketingSystem/icon/10.png'),
          text: '好友管理',
          desc: '利用渠道活码，可以统计每次推广添加的员工的好友数量',
          img: require('@/assets/image/marketingSystem/img/img-4/10.png'),
          checked: true
        }, {
          icon: require('@/assets/image/marketingSystem/icon/10.png'),
          text: '好友日志',
          desc: '可以查看每个部门及每一位营销人员的跟进统计以及每一次和用户的对话记录',
          img: require('@/assets/image/marketingSystem/img/img-4/11.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/11.png'),
          text: '企微群管理',
          desc: '可以对公司的客户群进行查询并管理，包括群人数 、活跃度 、群标签等',
          img: require('@/assets/image/marketingSystem/img/img-4/12.png'),
          checked: false
        }]
      }, {
        main_title: '促销管理',
        sub_title: '多重促销模式促进用户快速下单成交',
        rightImg: require('@/assets/image/marketingSystem/img/img-5/13.png'),
        list: [{
          icon: require('@/assets/image/marketingSystem/icon/12.png'),
          text: '优惠券',
          desc: '不同等级用户可以享有不同的优惠券，实现用户的忠诚度',
          img: require('@/assets/image/marketingSystem/img/img-5/13.png'),
          checked: true
        }, {
          icon: require('@/assets/image/marketingSystem/icon/13.png'),
          text: '定金券',
          desc: '对于高价产品，可以让用户先购买定金，后期再进行跟进',
          img: require('@/assets/image/marketingSystem/img/img-5/14.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/14.png'),
          text: '拼团活动',
          desc: '通过设置产品的拼团，促成用户快速下单购买',
          img: require('@/assets/image/marketingSystem/img/img-5/15.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/15.png'),
          text: '限时促销',
          desc: '节日期间可以设置限时促销，促进用户快速下单',
          img: require('@/assets/image/marketingSystem/img/img-5/16.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/16.png'),
          text: '产品打包',
          desc: '可以将某几个产品进行打包打折，并发送给用户进行支付',
          img: require('@/assets/image/marketingSystem/img/img-5/17.png'),
          checked: false
        }]
      }, {
        main_title: '团队管理',
        sub_title: '可以对进行社群营销的团队进行高效管理',
        rightImg: require('@/assets/image/marketingSystem/img/img-6/18.png'),
        list: [{
          icon: require('@/assets/image/marketingSystem/icon/17.png'),
          text: '人员等级',
          desc: '设置营销人员的等级和好友数量 RCRM容量',
          img: require('@/assets/image/marketingSystem/img/img-6/18.png'),
          checked: true
        }, {
          icon: require('@/assets/image/marketingSystem/icon/18.png'),
          text: '目标设置',
          desc: '可以设置每个销售人员的当月销售目标和好友数量，每天能看到和目标的距离',
          img: require('@/assets/image/marketingSystem/img/img-6/17.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/19.png'),
          text: '手动判单',
          desc: '可以将成交订单手工判单给营销人员',
          img: require('@/assets/image/marketingSystem/img/img-6/19.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/20.png'),
          text: '群发管理',
          desc: '管理并监控团队成员的群发行为，避免用户流失',
          img: require('@/assets/image/marketingSystem/img/img-6/20.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/29.png'),
          text: '超时管理',
          desc: '可以定义每个时段的企微回复时间要求，并进行每个人的回复超时统计',
          img: require('@/assets/image/marketingSystem/img/img-6/21.png'),
          checked: false
        }]
      }, {
        main_title: 'SOP管理',
        sub_title: '可以定义每个产品销售的SOP以及群运营的SOP，让营销团队工作更高效，难度更低',
        rightImg: require('@/assets/image/marketingSystem/img/img-7/22.png'),
        list: [{
          icon: require('@/assets/image/marketingSystem/icon/21.png'),
          text: '产品SOP',
          desc: '定义单个产品的SOP营销流程，体现在企微右侧，高效赋能营销人员',
          img: require('@/assets/image/marketingSystem/img/img-7/22.png'),
          checked: true
        }, {
          icon: require('@/assets/image/marketingSystem/icon/22.png'),
          text: '群SOP',
          desc: '设置各类群内营销的SOP流程，体现在企微右侧，高效赋能营销人员',
          img: require('@/assets/image/marketingSystem/img/img-7/22.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/23.png'),
          text: '素材库管理',
          desc: '可以将产品相关素材整理上传，呈现在微信右侧，营销人员可以随时发给用户',
          img: require('@/assets/image/marketingSystem/img/img-7/23.png'),
          checked: false
        }]
      }, {
        main_title: '营销看板',
        sub_title: '所有平台的销售额都同步至后台，一览数据概况',
        rightImg: require('@/assets/image/marketingSystem/img/img-8/24.png'),
        list: [{
          icon: require('@/assets/image/marketingSystem/icon/24.png'),
          text: '总销售统计',
          desc: '可以逐级查看每个部门及下级部门的销售业绩，以及销售业绩的排行',
          img: require('@/assets/image/marketingSystem/img/img-8/24.png'),
          checked: true
        }, {
          icon: require('@/assets/image/marketingSystem/icon/25.png'),
          text: '单品销售统计',
          desc: '可以查看每个产品的 当月销售额和每个销售人员卖出的销售额',
          img: require('@/assets/image/marketingSystem/img/img-8/25.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/26.png'),
          text: '优惠券统计',
          desc: '可以查看当月发放的优惠券以及优惠券转化率',
          img: require('@/assets/image/marketingSystem/img/img-8/26.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/27.png'),
          text: '定金券统计',
          desc: '可以查看当月销售的 定金券以及尾款回收情况',
          img: require('@/assets/image/marketingSystem/img/img-8/27.png'),
          checked: false
        }, {
          icon: require('@/assets/image/marketingSystem/icon/28.png'),
          text: '好友统计',
          desc: '可以逐级查看每个部门及个人本月增加和减少的微信好友以及活跃状态',
          img: require('@/assets/image/marketingSystem/img/img-8/28.png'),
          checked: false
        }]
      }],
      // 网站管理数据模拟
      website: [{
        main_title: '产品管理',
        sub_title: '迅速帮您建立产品体系并同步微店',
        rightImg: require('@/assets/image/siteAdministration/img/img-1/5.png'),
        list: [{
          icon: require('@/assets/image/siteAdministration/icon/1.png'),
          text: '产品管理',
          desc: '在线轻松创建并管理您的产品体系，实现PC和H5网页的完美呈现',
          img: require('@/assets/image/siteAdministration/img/img-1/5.png'),
          checked: true
        }, {
          icon: require('@/assets/image/siteAdministration/icon/2.png'),
          text: '同步微店',
          desc: '可以将您建立的产品一键轻松复制到您的多个微信小店',
          img: require('@/assets/image/siteAdministration/img/img-1/4.png'),
          checked: false
        }, {
          icon: require('@/assets/image/siteAdministration/icon/3.png'),
          text: '评价管理',
          desc: '多个维度管理购买用户的评价',
          img: require('@/assets/image/siteAdministration/img/img-1/3.png'),
          checked: false
        }, {
          icon: require('@/assets/image/siteAdministration/icon/4.png'),
          text: '质量控制',
          desc: '对上架的产品信息可以设置审核，避免错误产生',
          img: require('@/assets/image/siteAdministration/img/img-1/2.png'),
          checked: false
        }]
      }, {
        main_title: '自动建站',
        sub_title: '简单设置就能生成PC和手机网站，并同步微信小商店',
        rightImg: require('@/assets/image/siteAdministration/img/img-2/9.png'),
        list: [{
          icon: require('@/assets/image/siteAdministration/icon/5.png'),
          text: '广告位设计',
          desc: '可以设置各个栏目的轮播图广告',
          img: require('@/assets/image/siteAdministration/img/img-2/9.png'),
          checked: true
        }, {
          icon: require('@/assets/image/siteAdministration/icon/6.png'),
          text: '频道设置',
          desc: '可以设置需要的各个频道，比如产品、会员等',
          img: require('@/assets/image/siteAdministration/img/img-2/7.png'),
          checked: false
        }, {
          icon: require('@/assets/image/siteAdministration/icon/7.png'),
          text: '底部菜单',
          desc: '添加底部菜单，入关于我们、联系我们等',
          img: require('@/assets/image/siteAdministration/img/img-2/8.png'),
          checked: false
        }, {
          icon: require('@/assets/image/siteAdministration/icon/8.png'),
          text: '首页推荐',
          desc: '可以在首页添加你的推荐的产品，给予更多曝光',
          img: require('@/assets/image/siteAdministration/img/img-2/6.png'),
          checked: false
        }]
      }, {
        main_title: '弹窗系统',
        sub_title: '网站和h5弹窗高效接入用户咨询并转入私域',
        rightImg: require('@/assets/image/siteAdministration/img/img-3/14.png'),
        list: [{
          icon: require('@/assets/image/siteAdministration/icon/9.png'),
          text: '网站弹窗',
          desc: '用户来访网站，能让员工和用户高效率互动并引向私域',
          img: require('@/assets/image/siteAdministration/img/img-3/14.png'),
          checked: true
        }, {
          icon: require('@/assets/image/siteAdministration/icon/10.png'),
          text: '弹窗统计',
          desc: '统计部门到个人的当月每天的弹窗数量及优质回话、有效回话',
          img: require('@/assets/image/siteAdministration/img/img-3/12.png'),
          checked: false
        }, {
          icon: require('@/assets/image/siteAdministration/icon/11.png'),
          text: '在线时长',
          desc: '统计每个接待人员的在线时长',
          img: require('@/assets/image/siteAdministration/img/img-3/11.png'),
          checked: false
        }, {
          icon: require('@/assets/image/siteAdministration/icon/12.png'),
          text: '响应超时',
          desc: '统计每个人的响应超时次数',
          img: require('@/assets/image/siteAdministration/img/img-3/10.png'),
          checked: false
        }, {
          icon: require('@/assets/image/siteAdministration/icon/13.png'),
          text: '弹窗管理',
          desc: '可以设置某个或某类产品的弹窗接待人员，以及自动话术',
          img: require('@/assets/image/siteAdministration/img/img-3/13.png'),
          checked: false
        }]
      }, {
        main_title: '会员管理',
        sub_title: '建立会员折扣体系，增加用户的 忠诚度',
        rightImg: require('@/assets/image/siteAdministration/img/img-4/15.png'),
        list: [{
          icon: require('@/assets/image/siteAdministration/icon/14.png'),
          text: '会员设置',
          desc: '可以设置会员名称、收费期限和价格',
          img: require('@/assets/image/siteAdministration/img/img-4/15.png'),
          checked: true
        }, {
          icon: require('@/assets/image/siteAdministration/icon/15.png'),
          text: '会员业绩统计',
          desc: '可以查看当月每天会员的销售情况',
          img: require('@/assets/image/siteAdministration/img/img-4/16.png'),
          checked: false
        }, {
          icon: require('@/assets/image/siteAdministration/icon/16.png'),
          text: '会员到期提醒',
          desc: '可以查看即将到期的会员并提醒用户',
          img: require('@/assets/image/siteAdministration/img/img-4/17.png'),
          checked: false
        }]
      }],
      // 邮寄管理数据模拟
      service: [{
        main_title: '邮寄管理',
        sub_title: '打通京东和中通，打通个平台的订单，让邮寄管理更加高效',
        main_icon: require('@/assets/image/customerService/icon/1.png'),
        rightImg: require('@/assets/image/customerService/img/img-1/1.png'),
        list: [{
          icon: require('@/assets/image/customerService/icon/2.png'),
          text: '产品邮寄',
          desc: '各个平台销售的订单自动汇集到邮寄的目录，可以选择中通或者京东进行邮寄处理',
          img: require('@/assets/image/customerService/img/img-1/1.png'),
          bg_icon: require('@/assets/image/customerService/icon/3.png'),
          checked: true
        }, {
          icon: require('@/assets/image/customerService/icon/4.png'),
          text: '物流查询',
          desc: '可以查看每个订单的物流状态',
          img: require('@/assets/image/customerService/img/img-1/3.png'),
          bg_icon: require('@/assets/image/customerService/icon/5.png'),
          checked: false
        }, {
          icon: require('@/assets/image/customerService/icon/6.png'),
          text: '邮寄统计',
          desc: '统计当月的邮寄状态及费用情况',
          img: require('@/assets/image/customerService/img/img-1/2.png'),
          bg_icon: require('@/assets/image/customerService/icon/7.png'),
          checked: false
        }]
      }, {
        main_title: '积分商城',
        sub_title: '用户可以用积分对换礼品，提高用户的忠诚度',
        main_icon: '',
        rightImg: require('@/assets/image/customerService/img/img-2/4.png'),
        list: [{
          icon: require('@/assets/image/customerService/icon/8.png'),
          text: '原创礼品',
          desc: '可以发布自己公司定制的特色礼品让用户积分兑换',
          img: require('@/assets/image/customerService/img/img-2/4.png'),
          bg_icon: '',
          checked: true
        }, {
          icon: require('@/assets/image/customerService/icon/9.png'),
          text: '原创邮寄',
          desc: '打通京东和中通，用户兑换原创礼品后，打印面单并邮寄',
          img: require('@/assets/image/customerService/img/img-2/5.png'),
          bg_icon: '',
          checked: false
        }, {
          icon: require('@/assets/image/customerService/icon/10.png'),
          text: '原创兑换统计',
          desc: '可以查看当月原创礼品的兑换情况及明细',
          img: require('@/assets/image/customerService/img/img-2/6.png'),
          bg_icon: '',
          checked: false
        }, {
          icon: require('@/assets/image/customerService/icon/11.png'),
          text: '京东优选',
          desc: '选择京东上的商品作为礼品容许用户兑换，京东包邮发货',
          img: require('@/assets/image/customerService/img/img-2/7.png'),
          bg_icon: '',
          checked: false
        }, {
          icon: require('@/assets/image/customerService/icon/12.png'),
          text: '京东兑换统计',
          desc: '可以查看当月京东礼品的兑换情况及明细',
          img: require('@/assets/image/customerService/img/img-2/8.png'),
          bg_icon: '',
          checked: false
        }]
      }, {
        main_title: '用户管理',
        sub_title: '系统容许对特殊用户进行管理',
        main_icon: require('@/assets/image/customerService/icon/13.png'),
        rightImg: require('@/assets/image/customerService/img/img-3/10.png'),
        list: [{
          icon: require('@/assets/image/customerService/icon/14.png'),
          text: '受理登记',
          desc: '微信咨询自动归档，电话咨询录入归档，并跟踪解决',
          img: require('@/assets/image/customerService/img/img-3/10.png'),
          bg_icon: '',
          checked: true
        }, {
          icon: require('@/assets/image/customerService/icon/15.png'),
          text: '用户查询',
          desc: '查询用户信息并对用户进行各类操作',
          img: require('@/assets/image/customerService/img/img-3/9.png'),
          bg_icon: '',
          checked: false
        }, {
          icon: require('@/assets/image/customerService/icon/16.png'),
          text: '添加订单',
          desc: '遇到用户线下付款及对公付款，可以手动添加订单',
          img: require('@/assets/image/customerService/img/img-3/11.png'),
          bg_icon: '',
          checked: false
        }, {
          icon: require('@/assets/image/customerService/icon/17.png'),
          text: '白名单',
          desc: '公司内部的员工为白名单，取消某些限制',
          img: require('@/assets/image/customerService/img/img-3/12.png'),
          bg_icon: '',
          checked: false
        }, {
          icon: require('@/assets/image/customerService/icon/18.png'),
          text: '黑名单',
          desc: '禁止某些用户登录网站',
          img: require('@/assets/image/customerService/img/img-3/13.png'),
          bg_icon: '',
          checked: false
        }]
      }],
      // 注册
      telephone: '',
      mobile_code: '',
      aloading: false,
      is_get_code: false,
      codeBtnText: '获取验证码',
      codeTimer: null,
      codeCount: 59,
      customDialog: false
    }
  },
  mounted() {
    document.title = '金来客-营销大师'
    document.querySelector('meta[name="keywords"]').setAttribute('content', '金来客-企业微信服务商，提供微信营销,私域运营全套工具，包括引流获客、客户跟进转化、社群运营等全方位工具，帮助企业构建高转化率的私域流量池。')
    document.querySelector('meta[name="description"]').setAttribute('content', '企业微信营销,社群运营、企业微信SCRM,私域流量运营,微信营销软件,金来客')
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUpdate() {
    this.init()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    goAboutUs() {
      this.$router.push({
        path: 'aboutUs'
      })
    },
    // 存储每个模块到顶部的高度
    init() {
      setTimeout(() => {
        this.anhorList = []
        this.$nextTick(() => {
          const anhorTop = document.querySelectorAll('.anhorTop')
          Array.from(anhorTop).map((item) => {
            this.anhorList.push(item.offsetTop - 240) // 减去头部高度
          })
        })
        // console.log(this.anhorList, 'this.anhorList')
      }, 500)
    },
    // tab切换
    tabCheck(index) {
      __ZL__._tracker('JLKtabList', { 'tid': index + '' })
      this.tabIndex = index
      this.init()
    },
    lightCheck(index) {
      this.lightIndex = index
    },
    clearLight() {
      this.lightIndex = ''
    },
    // 侧边栏切换
    toFloor(index) {
      __ZL__._tracker('JLKnavList', { 'tid': this.tabIndex + '', 'fid': index + '' })
      console.log(index)
      this.emptyCheck()
      this.slideList[this.tabIndex].silde[index].checked = true
      this.$nextTick(() => {
        const element = document.querySelector('.anhorTop' + index) // 返回文档中匹配指定 CSS 选择器的一个元素
        const top = // 网页被卷去的高scrollTop  网页可见区域高：offsetHeight (包括边线的宽);
        element.offsetTop - window.scrollY // scrollY返回文档当前垂直滚动的像素数
        window.scrollBy({ top, left: 0 })
      })
    },
    handleScroll() {
      const top = document.body.scrollTop || document.documentElement.scrollTop
      // 滚动高亮tab
      for (let index = 0; index < this.anhorList.length; index++) {
        if (top < this.anhorList[0]) {
          this.emptyCheck()
          this.slideList[this.tabIndex].silde[0].checked = true
        }
        if (top > this.anhorList[index] && top < this.anhorList[index + 1]) {
          this.emptyCheck()
          this.slideList[this.tabIndex].silde[index].checked = true
        }
        if (top > this.anhorList[this.anhorList.length - 1]) {
          this.emptyCheck()
          this.slideList[this.tabIndex].silde[this.anhorList.length - 1].checked = true
        }
      }
    },
    // 侧边栏选项置空
    emptyCheck() {
      this.slideList[this.tabIndex].silde.map((item) => {
        item.checked = false
      })
    },
    // 营销系统
    checkModule(item, index, key) {
      __ZL__._tracker('JLKsaleList', { 'tid': index + '', 'fid': key + '' })
      console.log(index, key)
      this.marketing[index].list.map((item) => {
        item.checked = false
      })
      this.marketing[index].list[key].checked = true
      item.rightImg = this.marketing[index].list[key].img
    },
    // 网站管理切换
    checkModuleSite(item, index, key) {
      __ZL__._tracker('JLKsiteList', { 'tid': index + '', 'fid': key + '' })
      console.log(index, key)
      this.website[index].list.map((item) => {
        item.checked = false
      })
      this.website[index].list[key].checked = true
      item.rightImg = this.website[index].list[key].img
    },
    // 服务系统切换
    checkModuleServe(item, index, key) {
      __ZL__._tracker('JLKserviceList', { 'tid': index + '', 'fid': key + '' })
      this.service[index].list.map((item) => {
        item.checked = false
      })
      this.service[index].list[key].checked = true
      item.rightImg = this.service[index].list[key].img
    },
    // 点击注册
    clickRegIcon() {
      document.documentElement.scrollTop = document.body.scrollTop = 0
    },
    // 获取验证码
    getCodeAction() {
      const phone = this.telephone
      if (phone == '') {
        return this.$message.error('请输入手机号')
      }
      if (this.is_get_code || this.codeTimer) {
        return
      }
      this.is_get_code = true
      sendRegistCode({ phone: phone }).then(res => {
        this.is_get_code = false
        if (res.errNo === 0) {
          // 发送成功
          this.$message.success('验证码发送成功~')
          this.startTimer()
        }
      }).catch(() => {
        this.is_get_code = false
      })
    },
    // 验证码倒计时
    startTimer() {
      this.closeTimer()
      this.codeTimer = setInterval(this.timerAction, 1000)
      this.timerAction()
    },
    // 关闭倒计时
    closeTimer() {
      clearInterval(this.codeTimer)
      this.codeTimer = null
    },
    // 倒计时方法
    timerAction() {
      let time = this.codeCount
      if (time > 0) {
        const seconds = Math.floor(time % 60)
        const title = seconds + 's'
        time -= 1
        this.codeCount = time
        this.codeBtnText = title
      } else {
        this.closeTimer()
        this.codeCount = 59
        this.codeBtnText = '重新发送'
      }
    },
    // 立即注册
    registNow() {
      const phone = this.telephone
      if (phone == '') {
        return this.$message.error('请输入手机号')
      }
      const code = this.mobile_code
      if (code == '') {
        return this.$message.error('请输入验证码')
      }
      checkRegistCode({ phone: this.telephone, code: this.mobile_code }).then(res => {
        console.log(res)
        if (res.errNo === 0) {
          this.registSuccess()
        }
      })
    },
    // 注册成功
    registSuccess() {
      // this.$message.success('恭喜您，注册成功~')
      this.telephone = ''
      this.mobile_code = ''
      this.closeTimer()
      this.codeBtnText = '获取验证码'
      this.customDialog = true
      setTimeout(() => {
        this.customDialog = false
      }, 5000)
    }
  }
}
</script>
<style scoped lang="less">
.menu{
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  .item{
    margin-left: 60px;
    position: relative;
    cursor: pointer;
    .name::after{
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #395ddc;
    }
  }
}
.main{
  position: relative;
  text-align: left;
  top: 70px;
  background: #fff;
}
.content_classify_title{
  margin-top:20px;
}
.main_content_title{
  font-family: PingFang-SC-Bold;
  font-size: 38px;
  color: #333333;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}
.sub_content_title{
  font-family: PingFangSC-Regular;
  font-size: 22px;
  color: #666666;
  font-weight: 400;
  margin-top: -40px;
  text-align: center;
  margin-bottom: 40px
}
.slideBar{
  width: 110px;
  position: fixed;
  top: 330px;
  right: 50%;
  margin-right: 614px;
  background: #DCE5FF;
  border-radius: 19px;
  padding: 20px 20px;
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  .slideItem{
    cursor: pointer;
    display: flex;
    align-items: center;
    position:relative;
    // top:-10px;
    margin-bottom: 14px;
    .imgBox{
      display: inline-block;
      width: 25px;
      margin-right: 10px;
      // background: #EEF2FE;
      position: relative;
    }
    .circle{
      // margin-right: 10px;
      background: #DCE5FF;
      position: absolute;
      top:-7px;
      z-index: 2;
    }
  }
  .slideItem:nth-last-child(1){
    margin-bottom: 0;
  }
  .line{
    // height: 4px;
    position:absolute;
    top:8px;
    left:7px;
  }
  .active{
    color: #3A6BE4;
  }
}
.header{
  width: 100%;
  height: 70px;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 899;
  .headerBox{
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerLeft{
      display: flex;
      align-items: center;
      .aboutUs{
        cursor: pointer;
        margin-left: 20px;
        font-size: 16px;
      }
    }
    .logo{
      height: 50px;
    }
    .mobile{
      height: 28px;
      display: flex;
      align-items: center;
      span{
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 600;
        margin: 2px 8px 0 10px;
      }
    }
  }
}
.div_top{
  max-width: 2150px;
  height: 346px;
  // background: url('../../assets/image/banner.png') no-repeat;src/assets/image/banner_new.png
  background: url('../../assets/image/banner_20230810.png') no-repeat;
  background-position: center center;
  background-size: cover;
  background-position: 50%;
  margin:0 auto;
  // width: 1200px;

  // img{
  //   height: 100%;
  // }

  .reg_top {
    /deep/.el-input__inner {
      background-color: #FFFFFF;
      border: 1px solid rgba(216,224,240,1);
      box-shadow: 0px 1px 2px 0px rgba(184,200,224,0.22);
      border-radius: 5px !important;
    }
    /deep/.el-input__inner:focus {
      border-color: #EE2E2E !important;
    }
    width: 1200px;
    height: 346px;
    position: relative;
    margin: 0 auto 0;
    // background-color: rgba(0,0,0,0.35);
    .reg_box {
      position: absolute;
      top: 17px;
      right: 0px;
      width: 330px;
      height: 313px;
      background-color: #FFFFFF;
      box-shadow: 2px 2px 6px 0px rgba(131,145,166,0.35);
      border-radius: 6px;
      .top_title {
        margin-top: 37px;
        margin-bottom: 17px;
        height: 25px;
        line-height: 25px;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #0A1629;
        text-align: center;
        font-weight: 400;
      }
      .code_box {
        display: flex;
        .code_btn {
          cursor: pointer;
          margin-top: 18px;
          margin-left: 8px;
          display: inline-block;
          width: 80px;
          height: 36px;
          line-height: 36px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #7D8592;
          font-weight: 400;
          text-align: center;
          background-color: #FFFFFF;
          border: 1px solid rgba(216,224,240,1);
          box-shadow: 0px 1px 2px 0px rgba(184,200,224,0.22);
          border-radius: 5px;
        }
      }
      .input_item {
        padding-left: 45px;
        margin-bottom: 20px;
        .input_title {
          margin-bottom: 5px;
          height: 12px;
          line-height: 12px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #7D8592;
          font-weight: 400;
        }
        .inpw {
          width: 240px;
        }
        .inpw1 {
          width: 150px;
        }
      }
      .reg_bot {
        margin-top: 12px;
        .do_span {
          cursor: pointer;
          margin-left: 113px;
          text-align: center;
          display: inline-block;
          width: 108px;
          height: 36px;
          line-height: 36px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #FFFFFF;
          font-weight: 400;
          background-color: #3F8CFF;
          box-shadow: 0px 6px 12px 0px rgba(63,140,255,0.26);
          border-radius: 5px;
        }
      }
    }
  }
}

.main_content{
  width: 1200px;
  margin:0 auto;
  .tab{
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tabItem{
      width: 240px;
      cursor: pointer;
    }
  }
  .new_tab {
    width: 100%;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .tab_item {
      width: 290px;
      background-color: #FAFCFF;
      box-shadow: 0px 2px 4px 0px rgba(231,234,244,1);
      .tab_top {
        position: relative;
        width: 100%;
        height: 77px;
        line-height: 77px;
        background-color: #FAFCFF;
        .tab_top_txt {
          display: inline-block;
          width: 100%;
          height: 100%;
          text-align: center;
          font-family: PingFang-SC-Bold;
          font-size: 24px;
          color: #333333;
          font-weight: 700;
        }
        .hot_icon {
          position: absolute;
          left: 192px;
          top: 7px;
        }
      }
      .tab_bot {
        width: 290px;
        height: 226px;
        background-color: #FAFCFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .tab_bot_items {
          .item_txt {
            display: inline-block;
            margin-top: 13px;
            width: 50%;
            height: 25px;
            line-height: 25px;
            text-align: center;
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #333333;
            font-weight: 400;
          }
        }
        .tab_detail {
          cursor: pointer;
          margin-left: 93px;
          text-align: center;
          margin-bottom: 22px;
          width: 105px;
          height: 30px;
          line-height: 30px;
          background-color: #3A6BE4;
          border-radius: 2px;
          font-family: PingFang-SC-Bold;
          font-size: 16px;
          color: #FFFFFF;
          font-weight: 700;
        }
      }
      .active_color1 {
        background-color: #C8DEFF;
      }
      .active_color2 {
        background-color: #EAF2FF;
      }
    }
  }
  .tab_item:hover .tab_top {
    background-color: #C8DEFF;
  }
  .tab_item:hover .tab_bot {
    background-color: #EAF2FF;
  }
  .classify_title{
    text-align: center;
    margin-bottom: 30px;
    margin-top:20px;
    .main_title{
      font-family: PingFang-SC-Bold;
      font-size: 38px;
      color: #333333;
      font-weight: 700;
    }
    .sub_title,.functionTitle{
      font-family: PingFangSC-Regular;
      font-size: 24px;
      color: #666666;
      font-weight: 400;
    }
    .functionTitle{
      font-size: 22px;
    }
  }
}

.markingList,.websiteList{
  .markingItem{
    width: 1200px;
    margin: 0 auto;
    padding:20px  0 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .marking_left{
      .title{
        width: 122px;
        height: 34px;
        line-height: 34px;
        background: #2F6BFF;
        text-align: center;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 8px;
      }
      .subTitle{
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #666666;
        font-weight: 400;
        margin:10px;
      }
      .info{
        width: 483px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding:18px 26px;
        border-radius: 22px;
        // background: red;
        cursor: pointer;
        margin:10px 0;
        .info_left{
          margin-right: 16px;
        }
        .info_right{
         .info_title{
            font-family: PingFang-SC-Bold;
            font-size: 18px;
            color: #333333;
            font-weight: 700;
         }
         .info_desc{
            font-family: PingFang-SC-Regular;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
         }
        }
      }
      .info.active{
        background: #E5ECFF;
      }
      .info:hover{
         background: #E5ECFF;
      }
    }
    .marking_right{
      width: 700px;
      text-align: right;
      img{
        max-width: 100%;
      }
    }
  }
  .markingBox:nth-child(2n){
    background: #EDF2FF;
    .markingItem{
      min-height: 502px;
    }

    .marking_right{
      max-width: 700px;
      text-align: left;
      img{
        max-width: 100%;
      }
    }
  }
  .websiteBox{
    background: #EDF2FF;
  }
  .websiteBox:nth-child(2n){
    background: #fff;
    .markingItem{
      min-height: 502px;
    }
    .marking_right{
      max-width: 700px;
      text-align: left;
      img{
        max-width: 100%;
      }
    }
  }
}

.mailBox{
  margin-bottom:30px;
  .mailTop{
    padding: 10px 20px 120px;
    background: #E7EDFD;
    border-radius: 22px;
    .innerTop{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:0 80px;
      min-height: 235px;
    }
    .mail_left{
      .main_title{
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #2F6BFF;
        font-weight: 700;
      }
      .main_sub_title{
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #666666;
        font-weight: 400;
      }
    }
  }
  .mailBottom{
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    margin-top: -60px;
    .mailBotItem,.mailBotItemMore{
      position:relative;
      width: 260px;
      padding:50px 52px;
      background: #FDFEFF;
      box-shadow: 0px 2px 4px 0px rgba(211,221,245,1);
      border-radius: 20px;
      cursor:pointer;
      .title{
        font-family: PingFang-SC-Bold;
        font-size: 18px;
        color: #333333;
        font-weight: 700;
        margin: 10px 0;
      }
      .desc{
        font-family: PingFang-SC-Regular;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0.05px;
        font-weight: 400;
      }
      .bg_icon{
        position:absolute;
        right:0;
        bottom:0;
      }
    }
    .mailBotItem.on,.mailBotItemMore.on{
      transform: translateY(-50px);
    }
    .mailBotItemMore{
      width: 184.4px;
      padding: 20px 14px 50px;
    }
  }
}
.serveBox{
  .serveItem{
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .marking_left{
      .title{
        width: 122px;
        height: 34px;
        line-height: 34px;
        background: #2F6BFF;
        text-align: center;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 8px;
      }
      .subTitle{
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #666666;
        font-weight: 400;
        margin:10px;
      }
      .info{
        width: 483px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding:18px 26px;
        border-radius: 22px;
        // background: red;
        cursor: pointer;
        margin:10px 0;
        .info_left{
          margin-right: 16px;
        }
        .info_right{
         .info_title{
            font-family: PingFang-SC-Bold;
            font-size: 18px;
            color: #333333;
            font-weight: 700;
         }
         .info_desc{
            font-family: PingFang-SC-Regular;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
         }
        }
      }
      .info.active{
        background: #E5ECFF;
      }
      .info:hover{
        background: #E5ECFF;
      }
    }
    .marking_right{
      width: 700px;
      text-align: right;
      img{
        max-width: 100%;
      }
    }
  }
}
.bottom_title{
  padding: 20px 0;
  .black{
    color:#000 !important;
  }
}

.footer{
  min-height: 90px;
  // background: url('../../assets/image/footer.png') no-repeat;
  // background-position: center center;
  // // background-size: cover;
  // background-position: 50%;
  background-color: #F7F7F7;
  margin:0 auto;
  .infos {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-family: PingFangSC-Medium;
    text-align: center;
    color: #8F8F8F;
    font-size: 16px;
    font-weight: 500;
    img {
      margin-right: 3px;
      width: 22px;
      height: 22px;
      display: block;
    }
  }
}

.rightfloat{
  position: fixed;
  right: 2%;
  z-index: 999;
  bottom: 14%;
  text-align: center;
  .regBox {
    margin-left: 30px;
    width: 90px;
    height: 90px;
    margin-bottom: 20px;
    .regIcon {
      border-radius: 10px;
      cursor: pointer;
      width: 90px;
      height: 90px;
      display: block;
    }
  }
  .hotline{
    width: 150px;
    height: 230px;
    background: url('../../assets/leaflets/float.png');
    background-size:100% 100%;
    .guanbi{
      position: absolute;
      width: 10px;
      height: 10px;
      right: 2px;
      top: 2px;
      cursor: pointer;
      .el-icon-close{
        color: #ee2e2e;
      }
    }
    .text{
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      margin-bottom: 3px;
    }
    .hotlineImg{
      padding: 15px 0 0;
      margin: 0 auto 40px;
      img{
        width: 120px;
        height: 116px;
        margin-top: 3px;
      }
    }
  }
  .helpText{
    margin: 0 auto;
    width: 80%;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #476cfa;
    background: #fff;
    margin-top: 10px;
    cursor: pointer;
    border: 2px solid #476cfa;
    border-radius: 12px;
    img{
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}

.reg_dialog {
  /deep/.el-input__inner:focus {
    border-color: #EE2E2E !important;
  }
  /deep/.el-dialog {
    border-radius: 10px !important;
  }
  /deep/.el-dialog__body {
    padding: 0 0 !important;
  }
  /deep/.el-dialog__header {
    padding: 0 0 !important;
    border-radius: 10px !important;
  }
  /deep/.el-dialog__headerbtn {
    display: none !important;
  }
  .reg_body {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon11 {
      margin-top: 80px;
      width: 100px;
      height: 100px;
      display: block;
    }
    .text {
      margin-top: 45px;
      font-family: PingFangSC-Medium;
      font-size: 22px;
      color: #333;
      text-align: center;
      font-weight: 700;
      margin-bottom: 80px;
    }
  }
}

</style>
